@import url('https://fonts.googleapis.com/css2?family=Goldman:wght@700&family=Lobster&family=Montserrat:wght@200&family=Raleway:wght@800&family=Satisfy&family=Tajawal:wght@200&display=swap');

.navStart {
    background-color: rgb(62, 61, 61);
    

}

.title {
    font-family: 'Goldman';
    padding-left: 50px;
    padding-top: 5px;
    color: red;
    size-adjust: large;
}

.headLine {
    font-family: 'Tajawal', sans-serif;
    color: aliceblue;
    padding-left: 50px;
    padding-top: 13px;
}

.faceBook {
    padding-left: 350px;
    padding-top: 8px;
}

.faceButton {
    border-radius: 16px;
    background-color: lightgray;
}

.contactUs {
    padding-left: 20px;
    padding-top: 10px;
    border-radius: 16px;
}

